import React from "react"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"
import get from "lodash/get"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "@browniebroke/gatsby-image-gallery/dist/style.css"

const AboutPage = ({ data }) => {
  const title = get(data, "contentfulAboutPage.title")
  const html = get(data, "contentfulAboutPage.body.childMarkdownRemark.html")
  const gallerySections = get(data, "contentfulAboutPage.gallery.sections", [])

  const sectionTitleKeywords = gallerySections
    .map(({ title }) => title.toLowerCase())
    .join(', ')

  const keywords = gallerySections ?
    `over, about, ${title}, ${sectionTitleKeywords}` :
    `over, about, ${title}`

  return (
    <>
      <SEO title={title} keywords={keywords} />
      <Layout>
        <section>
          <h1>{title}</h1>
          <div
            className="mb-8 html"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {gallerySections.length > 0 &&
            gallerySections.map(({ title, subtitle, body, images }, i) => {
              const fullSizeImages = images.map(image => image.full.src)
              const thumbs = images.map(image => image.thumb)
              const html = get(body, "childMarkdownRemark.html")
              const articleClass = `
              border-gray-300 border-solid
              ${i > 0 ? "border-t mt-8 pt-8" : ""}
            `

              return (
                <article key={`article-${title}-${i}`} className={articleClass}>
                  {subtitle && (
                    <h3 className="mb-2 text-xs font-medium uppercase text-primary-700">
                      {subtitle}
                    </h3>
                  )}
                  {title && <h2 className="text:xl sm:text-3xl">{title}</h2>}
                  <div
                    className="html"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <Gallery images={fullSizeImages} thumbs={thumbs} />
                </article>
              )
            })}
        </section>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAboutPage(contentful_id: { eq: "2A7byFmBKQTZGtZ8cwcTQk" }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        sections {
          title
          subtitle
          body {
            childMarkdownRemark {
              html
            }
          }
          images {
            title
            description
            thumb: fluid(maxWidth: 150, maxHeight: 150) {
              ...GatsbyContentfulFluid
            }
            full: fluid(maxWidth: 1024, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default AboutPage
